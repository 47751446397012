<template>
    <div class="wizard-panel">
        <panel>
            <panel-top-bar variant="primary-dark" with-logo />
            <div class="panel-content">
                <h1 class="display-2 text-primary-dark">New Poster</h1>
                <h6 class="text-primary-dark">Create an account </h6>
                <div class="mt-5">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Email Address" />
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Password" />
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary btn-block btn-rounded">
                            Create Account
                        </button>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <social-button type="facebook" label="Facebook" icon="facebook-f" />
                        </div>
                        <div class="col-6">
                            <social-button type="google" label="Google" icon="google" />
                        </div>
                    </div>
                    <div class="mt-4">
                        <h6 class="mb-0 font-weight-normal">Already have an account? <a href="#">Login</a></h6>
                    </div>
                </div>
            </div>
        </panel>
    </div>
</template>

<script>
    import Panel from "../../components/Panel";
    import PanelTopBar from "../../components/PanelTopBar";
    import SocialButton from "../../components/SocialButton";

    export default {
        name: 'Authentication',
        components: {
            SocialButton,
            PanelTopBar,
            Panel
        }
    }
</script>
