<template>
    <a :href="link" :class="['btn btn-social btn-rounded', `btn-social-${type}`]">
        <span class="btn-icon">
            <fa :icon="['fab', icon]" />
        </span>
        {{ label }}
    </a>
</template>

<script>
    export default {
        name: 'SocialButton',
        props: {
            type: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            icon: {
                type: String,
                required: true
            },
            link: {
                type: String,
                default: '#'
            }
        }
    }
</script>
